import React, {useState, useEffect} from "react";
import { navigate } from "../util";
// import { navigate } from "@reach/router"
import qs from 'qs';
import { getQueryParam, buildModel } from "../util";
import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage(props) {
  useEffect(() => {
    var query = qs.parse(props.location.search, { ignoreQueryPrefix: true }).q;
    console.log(query);
    var model = buildModel(query);
    console.log(model);
    if(query && model) {
      navigate(`/results${getQueryParam(model)}`);
    } else {
      navigate(`/questions/strategy/1`);
    }
  }, []);

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
    </Layout>
  );
}

export default IndexPage;
